import "./VideoSection.scss";

function VideoSection(props) {
    return (
        <div className="char-section">
            <div className="section" >
                {props.charSide === "right" ? <h2 className="title" style={{ marginLeft: '40px'}} >{props.title}</h2> : <></> }
                {props.charSide === "left" ? <h2 className="title" style={{ marginLeft: '50px', marginBottom: '-80px', marginTop: '90px'}} >{props.title}</h2> : <></> }
                    <div className="video-char-container" >
                    <div className="left-char-back">
                        {props.charSide === "left" ? <img className="character" alt="character" src={props.character} style={{ transform: 'scaleX(-1)', marginRight: '-180px' }}></img> : <></>}
                        {props.charSide === "left" ?<div className="trap" style={{ 
                            borderBottom: `421px solid ${props.color}`,
                            borderRight: '350px solid transparent',
                            borderLeft: '0px solid transparent',
                            height: '114px',
                            width: '200px',
                            marginRight: '-285px',
                            zIndex: '-2' }} /> : <></>}
                    </div>
                    <div className="text-video">
                            <iframe
                                width= "560"
                                height= "315"
                                src={props.playlist}
                                title="YouTube video player"
                                frameborder="0"
                                allow="
                                    accelerometer;
                                    autoplay;
                                    clipboard-write;
                                    encrypted-media;
                                    gyroscope;
                                    picture-in-picture"
                                allowfullscreen>
                            </iframe>
                            <div className="text-box">
                                <p>{props.text}</p>
                            </div>
                    </div>
                </div>
            </div>
            {props.charSide === "right" ?<div className="trap" style={{ 
                borderBottom: `420px solid ${props.color}`,
                borderLeft: '350px solid transparent',
                borderRight: '0px solid transparent',
                height: '115px',
                width: '200px',
                marginLeft: '-510px',
                zIndex: '-2' }} /> : <></>}
            {props.charSide === "right" ? <img className="character" alt="character" src={props.character}></img> : <></>}
        </div>
    )
}

export default VideoSection