import "./Navbar.scss";
import { NavLink } from 'react-router-dom';

function Navbar() {

  return (
    <div className="bar">
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <img alt="logo" src="WhiteIcon_128.png"/>
        <img id="title" alt="title" src="Wordmark.png"/>
        {/* <div>
          <h1>
            Dreams Reach
          </h1>
          <p>Village of the Gods</p>
        </div> */}
      </div>
      <div className="right-bar">
        <NavLink to="/">Home</NavLink>
        <NavLink to="/art">Art</NavLink>
        <NavLink to="/presskit">Press Kit</NavLink>
        <a href="https://wiki.dreamsreach.com/index.php/Main_Page">Wiki</a>
      </div>
    </div>
  );
}
export default Navbar;
