import "./Trailer.scss";
import React from 'react';

function Trailer(){

    return (
        <div id="trailer">
            <div id="video">
                <iframe
                width= "784"//"560"
                height= "441"//"315"
                src="https://www.youtube.com/embed/_IxlXq_bdd8?autoplay=1&mute=1"
                title="YouTube video player"
                frameborder="0"
                allow="
                    accelerometer;
                    autoplay;
                    clipboard-write;
                    encrypted-media;
                    gyroscope;
                    picture-in-picture"
                allowfullscreen>
                </iframe>
            </div>
            <center>
                <iframe src="http://store.steampowered.com/widget/1559560/" frameborder="0" width="646" height="190" />
            </center>
        </div>
    )
}

export default Trailer;