import { useState } from "react";
import "./PressKit.scss";

function PressKit() {
    let [showBranding, setShowBranding] = useState("block");
    let [arrowBranding, setarrowBranding] = useState("˅");

    let [showVideo, setShowVideo] = useState("none");
    let [arrowVideo, setarrowVideo] = useState(">");

    let [showScreens, setShowScreens] = useState("none");
    let [arrowScreens, setarrowScreens] = useState(">");

    let [showGifs, setShowGifs] = useState("none");
    let [arrowGifs, setarrowGifs] = useState(">");

    function toggle(show, setShow, setArrow ) {
        if (show === "none") {
            setShowBranding("none");
            setarrowBranding(">");
            setShowScreens("none");
            setarrowScreens(">");
            setShowVideo("none");
            setarrowVideo(">");
            setShowGifs("none");
            setarrowGifs(">");

            setShow("block");
            setArrow("˅");
        } else {
            setShow("none");
            setArrow(">");
        }
    }

    return (
        <div id="press-kit">
            <p>
                <h3>Basic Info:</h3>
                <b>Developer:</b> Hinge Games Ltd. based in Montreal, Canada<br/>
                <b>Release Date:</b> June 28th 2021 (Demo)<br/>
                <b>Platform:</b> Steam<br/>
                <b>Website:</b> www.dreamsreach.com<br/>
                <b>Wiki:</b> wiki.dreamsreach.com<br/>
            </p>
            <h3>Description (Demo)</h3>
            <p>Delve into procedural dungeons, fighting enemies and solving puzzles. Then once you’ve gotten the hang of it, try building your own. Play around with our library of Puzzle Pieces, Warps and Treasure. Then publish your creation and share it on Social Media to challenge your friends!</p>
            <h3>Features (Demo)</h3>
            <ul>
                <li>Customizable Characters</li>
                <li>Drop in Drop out: Play coop with friends who can seamlessly drop in and out.</li>
                <li>Fast paced and Fluid combat</li>
                <li>Dual Stick Special attack system, use Dual Analog sticks to unleash special attacks.</li>
                <li>Endless procedural dungeons</li>
                <li>Puzzle Builder: Build your own Puzzles with Fire, Physics, Wind, and Water</li>
                <li>Dungeon Builder to build your own custom dungeons with Puzzles, Fights, Bosses, Treasure and even puzzles you've built.</li>
                <li>mod.io integration to share your creations</li>
                <li>Dungeon Browser that allows players to browse dungeons other player have made</li>
            </ul>

            <h3>Description (Full Game)</h3>
            <p>Dream's Reach: Village of the Gods is a Co-op Action-Adventure RPG awaiting your commands and creativity to come alive! Stealthily creep around perilous packs of ruthless enemies or confront them head-on with a vast arsenal of special weapons and items drawing from the powers of the elements. Solve physics based puzzles while exploring the glades and hidden paths of the hand-crafted overworld or delve into procedurally generated dungeons that provide ever-changing challenges for endless adventure!</p>
            <h3>Features (Full Game)</h3>
            <ul>
                <li>Journey through five distinct mythological worlds in search of your trusty pet while discovering the secrets of Dream's Reach</li>
                <li>Upgrade the HUB as you help new friends to unlock the Armory, Shops a Training Hall and more</li>
                <li>After you complete the game, play on a harder difficulty to discover even better loot</li>
                <li>Epic Boss fights</li>
                <li>New Abilities to Unlock</li>
                <li>New Puzzle Elements</li>
            </ul>
            <div className="buttons">
            <button type="button" onClick={ () => toggle(showBranding, setShowBranding, setarrowBranding) }>Branding {arrowBranding}</button><br/>
            <button type="button" onClick={ () => toggle(showVideo, setShowVideo, setarrowVideo) }>Video {arrowVideo}</button><br/>
            <button type="button" onClick={ () => toggle(showScreens, setShowScreens, setarrowScreens) }>Screen Shots {arrowScreens}</button><br/>
            <button type="button" onClick={ () => toggle(showGifs, setShowGifs, setarrowGifs) }>Gifs {arrowGifs}</button><br/>
            </div>
            <div id="branding" style={{ display: `${showBranding}` }}>
                <a href="./Branding/DreamsReachSplash4k">
                    <img alt="DreamsReachSplash4k" src="./Branding/DreamsReachSplash4k.png"/>
                </a>
                <a href="./Branding/DreamsReachHeroes.png">
                    <img alt="DreamsReachHeroes" src="./Branding/DreamsReachHeroes.png"/>
                </a>
                <a href="./Branding/DreamsReach_Logo_Rendered.png">
                    <img alt="DreamsReach_Logo_Rendered" src="./Branding/DreamsReach_Logo_Rendered.png"/>
                </a>
                <a href="./Branding/Symbol_Outline.png">
                    <img alt="Symbol_Outline" src="./Branding/Symbol_Outline.png"/>
                </a>
                <a href="./Branding/DreamsReach_Logo_Monochrome.png">
                    <img className="alpha" alt="DreamsReach_Logo_Monochrome" src="./Branding/DreamsReach_Logo_Monochrome.png"/>
                </a>
                <a href="./Branding/DreamsReach_Logo_Monochrome2.png">
                    <img alt="DreamsReach_Logo_Monochrome2" src="./Branding/DreamsReach_Logo_Monochrome2.png"/>
                </a>
                <a href="./Branding/DreamsReach_Wordmark.png">
                    <img className="alpha" alt="DreamsReach_Wordmark" src="./Branding/DreamsReach_Wordmark.png"/>
                </a>
                <a href="./Branding/DreamsReach_Wordmark2.png">
                    <img alt="DreamsReach_Wordmark2" src="./Branding/DreamsReach_Wordmark2.png"/>
                </a>
                <a href="./Branding/HingeGames_Logo.png">
                    <img className="alpha" alt="HingeGames_Logo" src="./Branding/HingeGames_Logo.png"/>
                </a>
                <a href="./Branding/HingeGames_Logo2.png">
                    <img alt="HingeGames_Logo2" src="./Branding/HingeGames_Logo2.png"/>
                </a>
                <a href="./Branding/HingeGames_Logo_Horizontal.png">
                    <img className="alpha" alt="HingeGames_Logo_Horizontal" src="./Branding/HingeGames_Logo_Horizontal.png"/>
                </a>
                <a href="./Branding/HingeGames_Logo_Horizontal2.png">
                    <img alt="HingeGames_Logo_Horizontal2" src="./Branding/HingeGames_Logo_Horizontal2.png"/>
                </a>
            </div>
            
            <div id="video" style={{ display: `${showVideo}` }}>
                <iframe
                    width= "560"
                    height= "315"
                    src="https://www.youtube.com/embed/videoseries?list=PLHLSNjPSQKhqWtlnI49sHlGKALR8uE7Fm&autoplay=1&loop=1&mute=1"
                    title="YouTube video player"
                    frameborder="0"
                    allow="
                        accelerometer;
                        autoplay;
                        clipboard-write;
                        encrypted-media;
                        gyroscope;
                        picture-in-picture"
                    allowfullscreen>
                </iframe><br/>
                <a href="https://www.youtube.com/watch?v=hcW_pgRe-Fk&list=PLHLSNjPSQKhqWtlnI49sHlGKALR8uE7Fm">Gameplay playlist</a>
            </div>
            
            <div id="screens" style={{ display: `${showScreens}` }}>
                <a href="./Screens/4PlayerDungeon.png">
                    <img alt="4PlayerDungeon" src="./Screens/4PlayerDungeon.png"/>
                </a>
                <a href="./Screens/CrescentVolley.png">
                    <img alt="CrescentVolley" src="./Screens/CrescentVolley.png"/>
                </a>
                <a href="./Screens/DungeonSneak.png">
                    <img alt="DungeonSneak" src="./Screens/DungeonSneak.png"/>
                </a>
                <a href="./Screens/DungeonBuilder.png">
                    <img alt="DungeonBuilder" src="./Screens/DungeonBuilder.png"/>
                </a>
                <a href="./Screens/DungeonCredits.png">
                    <img alt="DungeonCredits" src="./Screens/DungeonCredits.png"/>
                </a>
                <a href="./Screens/PuzzleBuildSimple.png">
                    <img alt="PuzzleBuildSimple" src="./Screens/PuzzleBuildSimple.png"/>
                </a>
                <a href="./Screens/SolvingPuzzle.png">
                    <img alt="SolvingPuzzle" src="./Screens/SolvingPuzzle.png"/>
                </a>
                <a href="./Screens/TowerAttack.png">
                    <img alt="TowerAttack" src="./Screens/TowerAttack.png"/>
                </a>
                <a href="./Screens/WaterPools.png">
                    <img alt="WaterPools" src="./Screens/WaterPools.png"/>
                </a>
            </div>
            
            <div id="gifs" style={{ display: `${showGifs}` }}>
                <a href="./Gifs/4 Player In Dungeon.gif">
                    <img alt="4 Player In Dungeon" src="./Gifs/4 Player In Dungeon.gif"/>
                </a>
                <a href="./Gifs/Combat Combo.gif">
                    <img alt="Combat Combo" src="./Gifs/Combat Combo.gif"/>
                </a>
                <a href="./Gifs/Combat.gif">
                    <img alt="Combat" src="./Gifs/Combat.gif"/>
                </a>
                <a href="./Gifs/Crescent Volley.gif">
                    <img alt="Crescent Volley" src="./Gifs/Crescent Volley.gif"/>
                </a>
                <a href="./Gifs/Deflect.gif">
                    <img alt="Deflect" src="./Gifs/Deflect.gif"/>
                </a>
                <a href="./Gifs/Disarm.gif">
                    <img alt="Disarm" src="./Gifs/Disarm.gif"/>
                </a>
                <a href="./Gifs/Dragon.gif">
                    <img alt="Dragon" src="./Gifs/Dragon.gif"/>
                </a>
                <a href="./Gifs/Dungeon Builder.gif">
                    <img alt="Dungeon Builder" src="./Gifs/Dungeon Builder.gif"/>
                </a>
                <a href="./Gifs/Dungeon Warp.gif">
                    <img alt="Dungeon Warp" src="./Gifs/Dungeon Warp.gif"/>
                </a>
                <a href="./Gifs/Falling.gif">
                    <img alt="Falling" src="./Gifs/Falling.gif"/>
                </a>
                <a href="./Gifs/Player Made Dungeon.gif">
                    <img alt="Player Made Dungeon" src="./Gifs/Player Made Dungeon.gif"/>
                </a>
                <a href="./Gifs/Procedural Dungeon.gif">
                    <img alt="Procedural Dungeon" src="./Gifs/Procedural Dungeon.gif"/>
                </a>
                <a href="./Gifs/Puzzle Builder.gif">
                    <img alt="Puzzle Builder" src="./Gifs/Puzzle Builder.gif"/>
                </a>
                <a href="./Gifs/Reveal.gif">
                    <img alt="Reveal" src="./Gifs/Reveal.gif"/>
                </a>
                <a href="./Gifs/SeeSaw Launch.gif">
                    <img alt="SeeSaw Launch" src="./Gifs/SeeSaw Launch.gif"/>
                </a>
                <a href="./Gifs/Sneaking Through the Bushes.gif">
                    <img alt="Sneaking Through the Bushes" src="./Gifs/Sneaking Through the Bushes.gif"/>
                </a>
                <a href="./Gifs/Stealth.gif">
                    <img alt="Stealth" src="./Gifs/Stealth.gif"/>
                </a>
                <a href="./Gifs/Treasure.gif">
                    <img alt="Treasure" src="./Gifs/Treasure.gif"/>
                </a>
                <a href="./Gifs/Tricky Treasure.gif">
                    <img alt="Tricky Treasure" src="./Gifs/Tricky Treasure.gif"/>
                </a>
                <a href="./Gifs/Triple Slam.gif">
                    <img alt="Triple Slam" src="./Gifs/Triple Slam.gif"/>
                </a>
                <a href="./Gifs/Wind Pushing Projectile.gif">
                    <img alt="Wind Pushing Projectile" src="./Gifs/Wind Pushing Projectile.gif"/>
                </a>
            </div>
        </div>
    )
}

export default PressKit;