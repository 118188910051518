import "./Odin.scss";

function Odin(props) {
    return (
        <div className="char-section">
            <div className="section2" >
                <h2 className="title" style={{ marginLeft: '175px', marginBottom: '-80px', marginTop: '90px'}} >{props.title}</h2>
                <div className="video-char-container2" >
                <div className="left-char-back2">
                    <img className="character" alt="character" src={props.character} style={{ transform: 'scaleX(-1)', marginRight: '-180px' }} />
                    <div className="trap" style={{ 
                        borderBottom: `421px solid ${props.color}`,
                        borderRight: '350px solid transparent',
                        borderLeft: '0px solid transparent',
                        height: '114px',
                        width: '200px',
                        marginRight: '-285px',
                        zIndex: '-2' }} />
                </div>
                <div className="video">
                        <iframe
                            width= "560"
                            height= "315"
                            src={props.playlist}
                            title="YouTube video player"
                            frameborder="0"
                            allow="
                                accelerometer;
                                autoplay;
                                clipboard-write;
                                encrypted-media;
                                gyroscope;
                                picture-in-picture"
                            allowfullscreen>
                        </iframe>
                        <div className="text-box">
                            <p className="text-box">{props.text}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Odin