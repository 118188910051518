import "./Home.scss";
import { NavLink } from 'react-router-dom';
import VideoSection from "./VideoSection";
import Odin from "./Odin";
import Brian from "./Brian";

function Home() {
    //links to playlists for each section
    const builderVids = "https://www.youtube.com/embed/videoseries?list=PLHLSNjPSQKhpSwXfQg7LtDwFVkgmrGSfs&autoplay=1&loop=1&mute=1";
    const CombatVids = "https://www.youtube.com/embed/videoseries?list=PLHLSNjPSQKhrgI1ErbMVhMUdouY_LitT4&autoplay=1&loop=1&mute=1";
    const PuzzleVids = "https://www.youtube.com/embed/videoseries?list=PLHLSNjPSQKhrcDZ9GKGWBEc-aKeLo--Sq&autoplay=1&loop=1&mute=1";
    const MultiplayerVids = "https://www.youtube.com/embed/videoseries?list=PLHLSNjPSQKhrepABJOSZ7smhnNvEEpfRX&autoplay=1&loop=1&mute=1";
    const SharingVids = "https://www.youtube.com/embed/videoseries?list=PLHLSNjPSQKhqVvnZXnYO-OnzHmyG7Ezjq&autoplay=1&loop=1&mute=1";

    //text for each section
    const builderText = "Build perplexing Puzzles and challenging Dungeons to share with your friends."
    const combatText = "Master the ranged and melee combat by learning the secrets for all your abilities and stringing together special attacks."
    const puzzleText = "Solve physics based puzzles, Use the elements of Fire Wind and Water."
    const multiplayerText = "Play and share with your friends with drop in drop out multiplayer"
    const sharingText = "Upload your creations on Mod.io and share with your friends on Facebook, twitter and, other social media."

    return (
        <>
            <div id="trailer">
                <div id="video">
                    <iframe
                    width= "784"//"560"
                    height= "441"//"315"
                    src="https://www.youtube.com/embed/videoseries?list=PLHLSNjPSQKhrIETOdyTG8-TTJ4FL5gaaT&autoplay=1&mute=1&rel=0"
                    title="YouTube video player"
                    frameborder="0"
                    allow="
                        accelerometer;
                        autoplay;
                        clipboard-write;
                        encrypted-media;
                        gyroscope;
                        picture-in-picture"
                    allowfullscreen />
                </div>
                <center>
                    <a href="https://store.steampowered.com/app/1559560/Dreams_Reach_Village_of_the_Gods/">
                        <img alt="SteamLink" src="./Branding/SteamLink.png"/>
                    </a>
                    {/*<iframe src="https://store.steampowered.com/widget/1559560/" frameborder="0" width="646" height="190" />*/}
                </center>
            </div>
        <div id="section-container">
            <VideoSection character="./CharacterCrops/Character_Neith.png" title="Dungeon Builder" charSide="right" playlist={builderVids} text={builderText} color="gold"/>
            <br/>
            <Odin character="./CharacterCrops/Character_Odin.png" title="Combat" charSide="left" playlist={CombatVids} text={combatText} color="teal" />
            <br/>
            <VideoSection character="./CharacterCrops/Character_Inanna.png" title="Puzzles" charSide="right" playlist={PuzzleVids} text={puzzleText} color="purple" />
            <br/>
            <VideoSection character="./CharacterCrops/Character_Momotaro.png" title="Multiplayer" charSide="left" playlist={MultiplayerVids} text={multiplayerText} color="maroon" />
            <br/>
            <Brian character="./CharacterCrops/Character_Brian.png" title="Sharing" charSide="right" playlist={SharingVids} text={sharingText} color="green" />
            <br/>
        </div>
        </>
    )
}

export default Home