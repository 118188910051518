import './App.scss';
import Navbar from './components/Navbar';
import Trailer from './components/Trailer';
import PressKit from './components/PressKit';
import Home from './components/Home';
import Art from './components/Art';
import Footer from './components/Footer';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Switch>
          <Route path="/art" component={Art} />
          <Route path="/presskit" component={PressKit} />
          <Route path="/" component={Home} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
