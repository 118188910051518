import "./Art.scss";
import { NavLink } from 'react-router-dom';

function Art() {

    return (
        <div className="art-container">
            <a href="KeyArt_01.jpg">
                <img alt="KeyArt" src="KeyArt_01.jpg"/>
            </a>
            <a href="Brian-letta.jpg">
                <img alt="Brian-sheet" src="Brian-letta.jpg"/>
            </a>
            <a href="Inanna-letta.png">
                <img alt="Inanna-sheet" src="Inanna-letta.png"/>
            </a>
            <a href="momotaro-letta.jpg">
                <img alt="Momotaro-sheet" src="momotaro-letta.jpg"/>
            </a>
            <a href="neith-letta.jpg">
                <img alt="Neith-sheet" src="neith-letta.jpg"/>
            </a>
            <a href="odin-letta-sheet.jpg">
                <img alt="Odin-sheet" src="odin-letta-sheet.jpg"/>
            </a>
            <div id="char-crop">
                <a href="./CharacterCrops/Character_Brian.png">
                    <img alt="Brian" src="./CharacterCrops/Character_Brian.png"/>
                </a>
                <a href="./CharacterCrops/Character_Inanna.png">
                    <img alt="Inana" src="./CharacterCrops/Character_Inanna.png"/>
                </a>
                <a href="./CharacterCrops/Character_Momotaro.png">
                    <img alt="Momotaro" src="./CharacterCrops/Character_Momotaro.png"/>
                </a>
                <a href="./CharacterCrops/Character_Neith.png">
                    <img alt="Neith" src="./CharacterCrops/Character_Neith.png"/>
                </a>
                <a href="./CharacterCrops/Character_Odin.png">
                    <img alt="Odin" src="./CharacterCrops/Character_Odin.png"/>
                </a>
            </div>
            <div id="environmental">
                <a href="./Environment_Asgard.jpg">
                    <img alt="Environment_Asgard" src="./Environment_Asgard.jpg"/>
                </a>
                <a href="./Environment_Chapel.jpg">
                    <img alt="Environment_Chapel" src="./Environment_Chapel.jpg"/>
                </a>
                <a href="./Environment_Gjallabru.jpg">
                    <img alt="Environment_Gjallabru" src="./Environment_Gjallabru.jpg"/>
                </a>
            </div>
        </div>
    )
}

export default Art