import "./Footer.scss";

function Footer() {

  return (
    <div className="foot-bar">
        <a href="https://www.facebook.com/hingegames" target="_blank">
            <img alt="Facebook" src="/SocialIcons/facebook_icon.png"/>
        </a>
        <a href="https://discord.gg/V35wcKA" target="_blank">
            <img className="discord" alt="Discord" src="/SocialIcons/discord_icon.png"/>
        </a>
        <a href="https://www.twitch.tv/hingegameslive" target="_blank">
            <img alt="Twitch" src="/SocialIcons/twitch_icon.png"/>
        </a>
        <a href="https://twitter.com/HingeGames" target="_blank">
            <img alt="Twitter" src="/SocialIcons/twitter_icon.png"/>
        </a>
        <a href="https://www.youtube.com/channel/UCvO4InwarCgsThkkmvVYz7Q" target="_blank">
            <img alt="Youtube" src="/SocialIcons/youtube_icon.png"/>
        </a>
    </div>
  );
}
export default Footer;
